import {
  GET_PARTY_ROOMS,
  BAN_ROOM,
  SEND_WARNING,
  BAN_HOST_FROM_APP,
  BAN_HOST_FROM_ROOMS
} from "./types";

const initialState = {
  partyRooms: [],
  bannedPartyRooms: [],
};

const PartyRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTY_ROOMS:
      return {
        ...state,
        partyRooms: action.payload,
      };
    case SEND_WARNING:
      return {
        ...state
      }
    case BAN_ROOM:
      return {
        ...state,
        partyRooms: state.partyRooms.map((partyRoom) => {
          if (partyRoom._id === action.payload.id) return action.payload.data;
          else return partyRoom;
        }),
      };
    default:
      return state;
  }
};

export default PartyRoomReducer;
