import React, { useEffect } from "react";

// routing
import { NavLink as Link } from "react-router-dom";

// alert
import { warning } from "../../util/Alert";

// redux
import { useDispatch } from "react-redux";

// types
import { UNSET_ADMIN } from "../../store/admin/types";

//MUI
import { makeStyles } from "@material-ui/core";

// jquery
import $ from "jquery";

const useStyles = makeStyles(() => ({
  navLink: {
    "&.active": {
      color: "#1976d2 !important",
      fontWeight: 500,
      fontSize: 16,
    },
    "&.active span": {
      color: "#1976d2 !important",
      fontWeight: 900,
    },
  },
}));

const Navbar = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleLogout = () => {
    const data = warning();
    data.then((isLogout) => {
      if (isLogout) {
        dispatch({ type: UNSET_ADMIN });
        window.location.href = "/";
      }
    });
  };

  useEffect(() => {
    $("").addClass("submenu-margin");
  }, []);
  return (
    <>
      <div className="page-sidebar">
        <Link to="/admin/dashboard">
          <span className="logo text-danger">VibeLynk</span>
        </Link>
        <ul className="list-unstyled accordion-menu">
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Dashboard">
            <Link to="/admin/dashboard" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="activity"></i>
              </span>
              Dashboard
            </Link>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Dashboard">
            <Link to="/admin/user" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="users"></i>
              </span>
              Users
            </Link>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Plan" className="pointer-cursor">
            <a href={() => false} className="add-collapse-margin" style={{ marginLeft: 0 }}>
              <span className="sidenav__icon">
                <i data-feather="radio"></i>
              </span>
              Live
              <i className="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul className="">
              <li>
                <Link to="/admin/partyRoom" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Party Room
                </Link>
              </li>
              <li>
                <Link to="/admin/videoLive" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Video Live
                </Link>
              </li>
            </ul>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Banner">
            <Link to="/admin/banner" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="image"></i>
              </span>
              Banner
            </Link>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Banner">
            <Link to="/admin/partyChallenge" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
              <i data-feather="box"></i>
              </span>
              Party Challenge
            </Link>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Plan" className="pointer-cursor">
            <a href={() => false} className="add-collapse-margin" style={{ marginLeft: 0 }}>
              <span className="sidenav__icon">
                <i data-feather="layout"></i>
              </span>
              Plan
              <i className="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul className="">
              <li>
                <Link to="/admin/diamondPlan" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Diamond Plan
                </Link>
              </li>
              <li>
                <Link to="/admin/vipPlan" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>VIP Plan
                </Link>
              </li>
            </ul>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Purchase Plan History" className="pointer-cursor">
            <a href={() => false} className="add-collapse-margin" style={{ marginLeft: 0 }}>
              <span className="sidenav__icon">
                <i data-feather="clock"></i>
              </span>
              Plan History
              <i className="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul className="">
              <li>
                <Link to="/admin/diamondPlan/history" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Diamond Plan
                </Link>
              </li>
              <li>
                <Link to="/admin/vipPlan/history" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>VIP Plan
                </Link>
              </li>
            </ul>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Game History">
            <Link to="/admin/gameHistory" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
              <i data-feather="hash"></i>
              </span>
              Game History
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Gift" className="pointer-cursor">
            <a href={() => false} className="add-collapse-margin" style={{ marginLeft: 0 }}>
              <span className="sidenav__icon">
                <i data-feather="gift"></i>
              </span>
              Gift
              <i className="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul className="">
              <li>
                <Link to="/admin/giftCategory" className={`${classes.navLink}`} onClick={() => localStorage.removeItem("GiftClick")}>
                  <i className="far fa-circle"></i>Categories
                </Link>
              </li>
              <li>
                <Link to="/admin/gift" className={`${classes.navLink}`} onClick={() => localStorage.setItem("GiftClick", true)}>
                  <i className="far fa-circle"></i>All Gifts
                </Link>
              </li>
            </ul>
          </li>


          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Gift" className="pointer-cursor">
            <a href={() => false} className="add-collapse-margin" style={{ marginLeft: 0 }}>
              <span className="sidenav__icon">
                <i data-feather="loader"></i>
              </span>
              Store
              <i className="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul className="">
              <li>
                <Link to="/admin/admissionCar" className={`${classes.navLink}`} onClick={() => localStorage.removeItem("GiftClick")}>
                  <i className="far fa-circle"></i>Admission Car
                </Link>
              </li>
              <li>
                <Link to="/admin/avatarFrame" className={`${classes.navLink}`} onClick={() => localStorage.setItem("GiftClick", true)}>
                  <i className="far fa-circle"></i>Avatar Frame
                </Link>
              </li>
              <li>
                <Link to="/admin/background" className={`${classes.navLink}`} onClick={() => localStorage.setItem("GiftClick", true)}>
                  <i className="far fa-circle"></i>Background
                </Link>
              </li>
              <li>
                <Link to="/admin/diamondShop" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Diamond Shop
                </Link>
              </li>
            </ul>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Gift" className="pointer-cursor">
            <a href={() => false} className="add-collapse-margin" style={{ marginLeft: 0 }}>
              <span className="sidenav__icon">
                <i data-feather="award"></i>
              </span>
              Rewards
              <i className="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul className="">
              <li>
                <Link to="/admin/sendReward" className={`${classes.navLink}`} >
                  <i className="far fa-circle"></i>Send Reward
                </Link>
              </li>
              <li>
                <Link to="/admin/rewardsHistory" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>History
                </Link>
              </li>
            </ul>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Sticker">
            <Link to="/admin/sticker" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="anchor"></i>
              </span>
              Sticker
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Theme">
            <Link to="/admin/theme" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="image"></i>
              </span>
              Theme
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Song">
            <Link to="/admin/song" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="music"></i>
              </span>
              Song
            </Link>
          </li>
          {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="Diamond Seller">
            <Link to="/admin/diamondSeller" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
              <i data-feather="dollar-sign"></i>
              </span>
              Diamond Seller
            </Link>
          </li> */}

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="DiamondSeller" className="pointer-cursor">
            <a href={() => false} className="add-collapse-margin" style={{ marginLeft: 0 }}>
              <span className="sidenav__icon">
                <i data-feather="dollar-sign"></i>
              </span>
              Diamond Seller
              <i className="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul className="">
              <li>
                <Link to="/admin/diamondSeller" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Diamond Sellers
                </Link>
              </li>
              <li>
                <Link to="/admin/diamondSellerShop" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Diamond Shop
                </Link>
              </li>
            </ul>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Hashtag">
            <Link to="/admin/hashtag" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="hash"></i>
              </span>
              Hashtag
            </Link>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Level">
            <Link to="/admin/level" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="bar-chart"></i>
              </span>
              Level
            </Link>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Post">
            <Link to="/admin/post" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="maximize"></i>
              </span>
              Post
            </Link>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Video">
            <Link to="/admin/video" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="film"></i>
              </span>
              Video
            </Link>
          </li>


          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Report" className="pointer-cursor">
            <a href={() => false} className="add-collapse-margin" style={{ marginLeft: 0 }}>
              <span className="sidenav__icon">
                <i data-feather="flag"></i>
              </span>
              Reports
              <i className="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul className="">
              <li>
                <Link to="/admin/reportedUser" className={`${classes.navLink}`} >
                  <i className="far fa-circle"></i>Reported Users
                </Link>
              </li>
              <li>
                <Link to="/admin/reportedMoments" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Reported Moments
                </Link>
              </li>
              <li>
                <Link to="/admin/reportedReels" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Reported Reels
                </Link>
              </li>
            </ul>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Complain" className="pointer-cursor">
            <a href={() => false} className="add-collapse-margin" style={{ marginLeft: 0 }}>
              <span className="sidenav__icon">
                <i data-feather="help-circle"></i>
              </span>
              Complain
              <i className="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul className="">
              <li>
                <Link to="/admin/pendingComplain" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Pending
                </Link>
              </li>
              <li>
                <Link to="/admin/solvedComplain" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Solved
                </Link>
              </li>
            </ul>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Redeem" className="pointer-cursor">
            <a href={() => false} className="add-collapse-margin" style={{ marginLeft: 0 }}>
              <span className="sidenav__icon">
                <i data-feather="key"></i>
              </span>
              Redeem
              <i className="fas fa-chevron-right dropdown-icon"></i>
            </a>
            <ul className="">
              <li>
                <Link to="/admin/pendingRedeem" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Pending
                </Link>
              </li>
              <li>
                <Link to="/admin/AcceptedRedeem" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Accepted
                </Link>
              </li>
              <li>
                <Link to="/admin/declineRedeem" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Declined
                </Link>
              </li>
              <li>
                <Link to="/admin/paymentMethod" className={`${classes.navLink}`}>
                  <i className="far fa-circle"></i>Payment Methods
                </Link>
              </li>
            </ul>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Google Ad">
            <Link to="/admin/deactivationRequests" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="user-x"></i>
              </span>
              Deactivation
            </Link>
          </li>

          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Google Ad">
            <Link to="/admin/advertisement" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="book"></i>
              </span>
              Google Ad
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Setting">
            <Link to="/admin/Setting" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="settings"></i>
              </span>
              Setting
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Profile">
            <Link to="/admin/profile" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="user"></i>
              </span>
              Profile
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Logout">
            <a href={() => false} onClick={handleLogout} className="add-collapse-margin">
              <i data-feather="log-out"></i>Logout
            </a>
          </li>
        </ul>
        <a href={() => false} id="sidebar-collapsed-toggle" style={{ opacity: 0, pointerEvents: "none" }}>
          <i data-feather="arrow-right"></i>
        </a>
      </div>
    </>
  );
};
export default Navbar;
