import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import { getPartyRooms, banUnbanRoom, sendWarning, banHostFromApp, banHostFromRooms } from "../store/partyRoom/action";

//routing
import { Link } from "react-router-dom";

//MUI
import { TablePagination, AppBar, Tabs, Tab } from "@material-ui/core";


//sweet alert
//alert
import { warningv2, alert, permissionError } from "../util/Alert";
import TabPanel from "../component/TabPanel";

const TablePaginationActions = React.lazy(() => import("../component/table/TablePagination"));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const PartyRoom = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tabValue, setTabValue] = useState(0);

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  useEffect(() => {
    props.getPartyRooms(); // eslint-disable-next-line
  }, []);

  const partyRooms = useSelector((state) => state.partyRoom.partyRooms);

  useEffect(() => {
    setData(partyRooms);
    console.log("list of party rooms: ", partyRooms);
  }, [partyRooms]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  }



  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase()
      ? e.target.value.trim().toUpperCase()
      : e.target.value.trim();

    if (value) {
      const data = partyRooms.filter((data) => {
        return (
          data?.liveUserUniqueId.toString().indexOf(value) > -1 ||
          data?.roomName?.toUpperCase()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(partyRooms);
    }
  };

  const sendWarning = (liveUserData) => {
    console.log("sendWarning data: ", liveUserData);

    const data = warningv2("SEND WARNING", "Are you sure you want to send warning to this room?");
    data.then((aBoolean) => {
      if (aBoolean) {
        if (!hasPermission) return permissionError();
        props.sendWarning(liveUserData._id);
        alert("Success!", `Room has been sent a warning!`, "success");
      }
    })
      .catch((error) => console.log(error))
  }

  const banUnBanRoom = (liveUserData) => {

    const data = warningv2("BAN ROOM", "Are you sure you want to ban this room?");
    data.then((aBoolean) => {
      if (aBoolean) {
        if (!hasPermission) return permissionError();
        props.banUnbanRoom(liveUserData._id);
        alert("Success!", `Room has been banned!`, "success");
      }
    })
      .catch((error) => console.log(error))
  }

  const banHostFromApp = (liveUserData) => {
    console.log("sendWarning data: ", liveUserData);

    const data = warningv2("BAN HOST", "Are you sure you want to ban the host of this room?");
    data.then((aBoolean) => {
      if (aBoolean) {
        if (!hasPermission) return permissionError();
        props.banHostFromApp(liveUserData.liveUserId);
        alert("Success!", `Host has been banned`, "success");
      }
    })
      .catch((error) => console.log(error))
  }

  const banHostFromRooms = (liveUserData) => {
    console.log("sendWarning data: ", liveUserData);

    const data = warningv2("BAN HOST", "Are you sure you want to ban the host of this room?");
    data.then((aBoolean) => {
      if (aBoolean) {
        if (!hasPermission) return permissionError();
        props.banHostFromRooms(liveUserData._id);
        alert("Success!", `Host has been banned`, "success");
      }
    })
      .catch((error) => console.log(error))
  }


  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Party Room</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Party Room
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <AppBar position="static">
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="Party Room Tabs"
          indicatorColor="primary"
        >
          <Tab label="Online Party Room" {...a11yProps(0)} />
          <Tab label="Banned Party Room" {...a11yProps(1)} />
          <Tab label="Banned Users" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      {/*Start Region Online Party Rooms */}
      <TabPanel value={tabValue} index={0}>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header pb-0">
                <div className="row my-3">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                    <form action="">
                      <div className="input-group mb-3 border rounded-pill">
                        <div className="input-group-prepend border-0">
                          <div id="button-addon4" className="btn text-danger">
                            <i className="fas fa-search mt-2"></i>
                          </div>
                        </div>
                        <input
                          type="search"
                          placeholder="What're you searching for?"
                          aria-describedby="button-addon4"
                          className="form-control bg-none border-0 rounded-pill searchBar"
                          onChange={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="card-body card-overflow">
                <div className="d-sm-flex align-items-center justify-content-between mb-4"></div>

                <table className="table" style={{ borderCollapse: "collapse" }}>
                  <thead className="text-center">
                    <tr>
                      <th>No</th>
                      <th>VibeLynk ID</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Ban duration</th>
                      <th>Viewers</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {data.length > 0 ? (
                      (rowsPerPage > 0
                        ? data.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        : data
                      ).map((data, index) => {
                        return (
                          <>
                            <tr
                            >
                              <td>{index + 1}</td>
                              <td>{data?.liveUserUniqueId || ""}</td>
                              <td>
                                <img
                                  height="50px"
                                  width="50px"
                                  alt="app"
                                  className="mx-auto"
                                  src={data.image}
                                  style={{
                                    boxShadow:
                                      "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                    border: "2px solid #fff",
                                    borderRadius: 10,
                                    objectFit: "cover",
                                    display: "block",
                                  }}
                                  onerror='this.src=,`${baseURL}storage/male.png`"'
                                />
                              </td>
                              <td>{data?.roomName || ""}</td>
                              <td>{data._id?._id}</td>
                              <td className="text-danger">{data?.viewers.length || 0}</td>
                              <td className="pointer-cursor">
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                  <span className={data?.isBanned ? "text-danger" : "text-danger-2"} onClick={() => banUnBanRoom(data)}>{data?.isBanned ? 'Unban Room' : 'Ban Room'}</span>
                                  <span className="text-danger-2 mt-2" onClick={() => banHostFromApp(data)}>Ban Host From App</span>
                                  <span className="text-danger-2 mt-2" onClick={() => banHostFromRooms(data)}>Ban Host From Rooms</span>
                                  <span className="text-warning mt-2" onClick={() => sendWarning(data)}>Send Warning</span>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7" align="center">
                          Nothing to show!!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <TablePagination
                  id="pagination"
                  component="div"
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      {/*End Region Online Party Rooms */}

      {/* Start Region Banned Party Rooms */}
      {/* End Region Banned Party Roms */}

      {/* Start Region Banned Users */}
      {/* End Region Banned Users */}

    </>
  );
};

export default connect(null, { getPartyRooms, sendWarning, banUnbanRoom, banHostFromApp, banHostFromRooms })(PartyRoom);
