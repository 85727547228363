import { combineReducers } from "redux";

import adminReducer from "./admin/reducer";
import bannerReducer from "./banner/reducer";
import coinPlanReducer from "./coinPlan/reducer";
import vipPlanReducer from "./vipPlan/reducer";
import giftCategoryReducer from "./giftCategory/reducer";
import spinnerReducer from "./spinner/reducer";
import giftReducer from "./gift/reducer";
import songReducer from "./song/reducer";
import hashtagReducer from "./hashtag/reducer";
import levelReducer from "./level/reducer";
import userReducer from "./user/reducer";
import postReducer from "./post/reducer";
import videoReducer from "./video/reducer";
import followerReducer from "./follower/reducer";
import settingReducer from "./setting/reducer";
import advertisementReducer from "./advertisement/reducer";
import complainReducer from "./complain/reducer";
import redeemReducer from "./redeem/reducer";
import dashboardReducer from "./dashboard/reducer";
import reportedUserReducer from "./reportedUser/reducer";
import stickerReducer from "./sticker/reducer";
import themeReducer from "./Theme/theme.reducer";
import deactivationRequestReducer from "./deactivationRequest/reducer";
import rewardReducer from "./reward/reducer";
import inventoryReducer from "./inventory/reducer";
import backgroundReducer from "./background/reducer";
import reportedMomentReducer from "./reportedMoment/reducer";
import { gameHistoryReducer } from "./GameHistory/reducer";
import { admissionSVGAReducer } from "./AdmissionCar/reducer";
import { avatarFrameReducer } from "./AvatarFrame/reducer";
import { coinSellerReducer } from "./coinSeller/reducer";
import ReportedReelsReducer from "./reportedReel/reducer";
import partyChallengeLevelReducer from "./partyChallenge/reducer";
import diamondShopReducer from "./diamondShop/reducer";
import paymentMethodReducer from "./paymentMethod/reducer";
import payoutReducer from "./payout/reducer";
import PartyRoomReducer from "./partyRoom/reducer";
import VideoLiveReducer from "./videoLive/reducer";


export default combineReducers({
  admin: adminReducer,
  user: userReducer,
  post: postReducer,
  banner: bannerReducer,
  song: songReducer,
  gift: giftReducer,
  admissionSVGA: admissionSVGAReducer,
  avatarFrame: avatarFrameReducer,
  video: videoReducer,
  level: levelReducer,
  sticker: stickerReducer,
  complain: complainReducer,
  redeem: redeemReducer,
  report: reportedUserReducer,
  dashboard: dashboardReducer,
  hashtag: hashtagReducer,
  followersFollowing: followerReducer,
  giftCategory: giftCategoryReducer,
  vipPlan: vipPlanReducer,
  coinPlan: coinPlanReducer,
  setting: settingReducer,
  advertisement: advertisementReducer,
  spinner: spinnerReducer,
  gameHistory: gameHistoryReducer,
  theme: themeReducer,
  coinSeller: coinSellerReducer,
  deactivationRequest: deactivationRequestReducer,
  reward: rewardReducer,
  inventory: inventoryReducer,
  background: backgroundReducer,
  reportedMoment: reportedMomentReducer,
  reportedReel: ReportedReelsReducer,
  partyChallengeLevel: partyChallengeLevelReducer,
  diamondShop: diamondShopReducer,
  paymentMethod: paymentMethodReducer,
  payout: payoutReducer,
  partyRoom: PartyRoomReducer,
  videoLive: VideoLiveReducer,
});
