import {
  GET_VIDEO_LIVE,
  BAN_HOST_FROM_APP,
  SEND_WARNING,
  BAN_ROOM,
  BAN_HOST_FROM_ROOMS
} from "./types";

const initialState = {
  videoLives: [],
};

const VideoLiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEO_LIVE:
      return {
        ...state,
        videoLives: action.payload,
      };
    case SEND_WARNING:
      return {
        ...state
      }
    case BAN_HOST_FROM_APP:
      return {
        ...state,
      }
    case BAN_ROOM:
      return {
        ...state,
        videoLives: state.videoLives.map((videoLive) => {
          if (videoLive._id === action.payload.id) return action.payload.data;
          else return videoLive;
        }),
      };
    default:
      return state;
  }
};

export default VideoLiveReducer;
