import axios from "axios";
import { Toast } from "../../util/Toast";

import {
  GET_PARTY_ROOMS,
  BAN_HOST_FROM_APP,
  BAN_HOST_FROM_ROOMS,
  BAN_ROOM,
  SEND_WARNING
} from "./types";

export const getPartyRooms = () => (dispatch) => {
  axios
    .get("/liveUser/getLiveUsersForAdmin?type=audio")
    .then((res) => {
      if (res.data.status) {
        console.log("response party room: ", res.data)
        dispatch({ type: GET_PARTY_ROOMS, payload: res.data.liveUsers });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const sendWarning = (id) => (dispatch) => {
  axios
    .post(`/liveUser/sendWarning?id=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: SEND_WARNING, payload: res.data.status });
      } else {
        Toast("error", res.data.message);
      }
      console.log("sendWarning response: ", res.data)
    })
    .catch((error) => Toast("error", error.message));
};


export const banUnbanRoom = (id) => (dispatch) => {
  axios
    .post(`/liveUser/banUnbanRoom?id=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: BAN_ROOM, payload: { data: res.data.liveUser, id: id} });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const banHostFromRooms = (id) => (dispatch) => {
  axios
    .post(`/liveUser/banHost?id=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: BAN_HOST_FROM_ROOMS, payload: res.data.user });
      } else {
        Toast("error", res.data.message);
      }
      console.log("banHost response: ", res.data)
    })
    .catch((error) => Toast("error", error.message));
};

export const banHostFromApp = (userId) => (dispatch) => {
  axios
    .patch(`blockUnblock/${userId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: BAN_HOST_FROM_APP, payload: res.data.user });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};